const UPDATE_PRICE_DISPLAY_DISCLAIMER = "updatePriceDisplayDisclaimer";
const SET_CREATE_OFFER_WAREHOUSE = "setCreateOfferWarehouse";
const SET_CREATE_OFFER_SERVICE = "setCreateOfferService";

const state = {
  priceDisplayDisclaimer: true,
  createOfferWarehouse: null,
  createOfferService: "online"
};
const getters = {
  getCreateOfferWarehouse: state => {
    return state.createOfferWarehouse;
  },
  getCreateOfferService: state => {
    return state.createOfferService;
  }
};

const actions = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]({ commit }, value) {
    commit(UPDATE_PRICE_DISPLAY_DISCLAIMER, value);
  },
  [SET_CREATE_OFFER_WAREHOUSE]({ commit }, value) {
    commit(SET_CREATE_OFFER_WAREHOUSE, value);
  },
  [SET_CREATE_OFFER_SERVICE]({ commit }, value) {
    commit(SET_CREATE_OFFER_SERVICE, value);
  }
};
const mutations = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]: (state, value) => {
    state.priceDisplayDisclaimer = value;
  },
  [SET_CREATE_OFFER_WAREHOUSE]: (state, value) => {
    state.createOfferWarehouse = value;
  },
  [SET_CREATE_OFFER_SERVICE]: (state, value) => {
    state.createOfferService = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
