<template>
  <ProductListSlider
    :config="config"
    :products="products"
    :categoryBlockName="'ProductSlider'"
    :showMore="showMore"
  />
</template>

<script>
import ProductListSlider from "./elements/ProductListSlider.vue";

// import categoryBlockType from "./categoryBlockType";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
// import analyticsService from "~/service/analyticsService";
import Vue from "vue";

export default {
  name: "ProductSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  components: { ProductListSlider },
  mixins: [deliveryReactive],
  data() {
    return {
      products: [],
      showMore: false
    };
  },
  methods: {
    // handleView(entries, observer, isIntersecting) {
    //   if (isIntersecting) {
    //     analyticsService.viewPromotions(
    //       [this.proposal],
    //       this.position,
    //       this.products
    //     );
    //   }
    // },
    async reload() {
      this.products = [];

      let categoryId = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_ProductSlider.CATEGORY.categoryId"
      );
      if (categoryId) {
        let response = await ProductService.search({
          parent_category_id: categoryId,
          page_size: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductSlider.PRODUCT_LIMIT",
            12
          )
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
        if (!this.products || this.products.length < 1) {
          this.$emit("hide");
        }
        // this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
