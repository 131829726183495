<template>
  <v-card
    outlined
    rounded="sm"
    class="product-card product"
    :id="`product-card-${product.slug}`"
    :to="{ name: linkComponentName, params: { slug: product.slug } }"
    ref="productCard"
  >
    <slot name="headers">
      <div class="badges d-flex justify-end align-top w-100 top">
        <div class="product-badge" v-if="product.badge">
          {{ product.badge.label }}
        </div>
        <v-tooltip left :key="heartKey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click.stop.prevent="addToFavorites"
              @mousedown.stop
              aria-label="Aggiungi ad una lista"
            >
              <v-icon color="primary" v-if="highlight">$heartfull</v-icon>
              <v-icon v-else color="grey">$heart</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("product.addToList") }}</span>
        </v-tooltip>
        <div class="product-classes">
          <ProductAcqLimit product="product" />
          <ProductClass
            :pClass="pClass"
            v-for="pClass in productClasses"
            :key="pClass.productClassid"
          />
        </div>
      </div>
    </slot>
    <div class="d-flex flex-column w-100 h-100 card-content">
      <div class="d-flex flex-row flex-sm-column body">
        <img
          :src="product.mediaURL"
          onerror="this.onerror=null;this.src='/no-icon.png'"
          class="product-img align-self-center"
          :alt="'Immagine' + product.name"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
        />

        <div
          class="description default--text d-flex flex-column justify-left  mt-3 ml-1 ml-sm-0"
        >
          <h2 class="name font-weight-bold">{{ product.name }}</h2>

          <span class="short_descr font-weight-bold">
            {{ product.shortDescr }}
          </span>
          <slot name="productdescr">
            <span
              v-if="
                product.productInfos.TIPOLOGIA == 'Pezzo' &&
                  product.priceUmDisplay
              "
              class="descr"
            >
              <span class="product-package font-weight-bold"
                >{{
                  product.productInfos.PACKAGE_DESCR
                    ? product.productInfos.PACKAGE_DESCR
                    : product.description
                }}
              </span>

              <span class="weight-unit" v-if="createOfferService == 'online'"
                >al {{ product.weightUnitDisplay }}
                {{ $n(product.priceUmDisplay, "currency") }}</span
              ></span
            >
            <span
              v-else-if="
                product.productInfos.TIPOLOGIA == 'Confezione' &&
                  createOfferService == 'online'
              "
              ><span class="weight-unit" ng-if="product.priceUmDisplay"
                >al {{ product.weightUnitDisplay }}
                {{ $n(product.priceUmDisplay, "currency") }}</span
              ></span
            >

            <!-- <span class="descr">
                <span class="font-weight-bold">{{ product.description }}</span>
                <span
                  class="weight-unit"
                  v-if="
                    product.productInfos &&
                      product.productInfos.TIPOLOGIA == 'Pezzo' &&
                      product.priceUmDisplay
                  "
                >
                  al {{ product.weightUnitDisplay }}
                  {{ $n(product.priceUmDisplay, "currency") }}
                </span>
              </span> -->
          </slot>
          <span class="cod-int text-caption d-none">
            cod. {{ product.codInt }}
          </span>
          <span
            class="option d-flex align-center justify-space-between line-height-1 mt-2"
            style="white-space: initial;text-align: left;"
            v-for="(value, label) in selectedOptions"
            :key="label"
          >
            <i>
              Preferenza: <b> {{ value }}</b>
            </i>
          </span>
        </div>
      </div>

      <!-- <div
          v-if="selectedOptions.customWeight"
          class="cart-item-info"
          @click.stop.prevent="openOptionsModal(product.selectOptions)"
        >
          <em>Grammatura: {{ selectedOptions.customWeight }}gr</em>
        </div> -->
      <!-- v-if="product.priceDisplay && product.available > 0" -->
      <slot name="actions">
        <ProductPrice v-if="product.priceDisplay" :product="product" />
        <div
          v-if="product.available > 0"
          class="actions mt-auto"
          @click.stop.prevent
        >
          <SelectedOptionsMenu
            v-if="quantity <= 0"
            :product="product"
            :selectOptionsArray="selectOptionsArray"
            :size="'medium'"
            @onOptionChanged="onOptionChanged"
            @setSelectOptions="setSelectOptions"
          />
          <ProductQty
            :key="key"
            :selectedOptions="selectedOptions"
            :product="product"
            :position="position"
            :inCart="inCart"
          />
        </div>
        <div
          v-else
          class="actions justify-center text-center text-caption error--text font-weight-bold"
        >
          {{ $t("product.notAvailable") }}
        </div>
      </slot>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
      width: 100px;
      height: 100px;
    }
  }
  .actions {
    min-height: auto !important;
    .v-menu__content {
      border-radius: $border-radius-root !important;
    }
    .selectOptions {
      margin-right: 10px;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
}
</style>
<script>
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductQty from "./ProductQty.vue";
import ProductClass from "./ProductClass.vue";

import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";
import productVariations from "@/mixins/productVariations";

import { mapState, mapGetters } from "vuex";

import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined },
    linkComponentName: { type: String, default: "Product" },
    createOfferService: { type: String, default: undefined }
  },
  components: {
    ProductClass,
    ProductAcqLimit,
    ProductPrice,
    ProductQty,
    SelectedOptionsMenu
  },
  mixins: [productMixin, productVariations],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      key: 0,
      localProduct: this.product
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    inCart() {
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item != null;
    }
    // item() {
    //   return null;
    // }
  },
  methods: {
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleSelectedVariation(id) {
      let p = this.product.variations.find(product => product.productId === id);
      let v = this.product.variations;
      this.product = p;
      this.product.variations = v;
    }
  },
  mounted() {
    if (this.product.selectOptions && this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  }
};
</script>
