<template>
  <ProductListSlider
    :config="config"
    :products="filteredProducts"
    :categoryBlockName="'CrmProductSlider'"
  />
</template>
<style lang="scss">
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
.product-slider {
  .proposal-img {
    margin-bottom: 10px;
  }
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 380px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      .v-image {
        min-height: 110px;
      }
    }
    .proposal-card {
      height: 285px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
  }
  .firstCard {
    float: left;
  }
}
</style>
<script>
// import ProductCard from "@/components/product/ProductCard.vue";
import ProductListSlider from "./elements/ProductListSlider.vue";

import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import { filterProductsInCart } from "~/service/ebsn";

import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "CrmProductListSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    },
    productId: {
      type: Number,
      required: false
    },
    categoryId: {
      type: Number,
      required: false
    }
  },
  mixins: [
    deliveryReactive
    //  categoryBlockType
  ],
  components: {
    // ProductCard,
    ProductListSlider
  },
  data() {
    return {
      products: [],
      categoryBlockName: this.$options.name
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    filteredProducts() {
      if (this.products) {
        let removeCart = Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CrmProductSlider.REMOVE_CART",
          false
        );
        if (removeCart && this.cart) {
          return filterProductsInCart(this.products, this.cart);
        } else {
          return this.products;
        }
      }
      return [];
    }
  },
  methods: {
    async reload() {
      this.products = [];

      let algorithm = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_CrmProductSlider.ALGORITHM"
      );

      if (algorithm) {
        let params = {
          layout: algorithm,
          limit: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CrmProductSlider.PRODUCT_LIMIT",
            12
          ),
          shuffle: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CrmProductSlider.PRODUCT_RANDOM",
            false
          ),
          promo: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CrmProductSlider.PROMO",
            false
          )
        };
        if (this.productId) params.product_id = this.productId;
        if (this.categoryId) params.category_id = this.categoryId;

        let response = await ProductService.adv(params);
        this.products = response.products;
        // if (response.products) {
        //   let removeCart = Vue.$ebsn.meta(
        //     this.config,
        //     "categoryblocktype_CrmProductSlider.REMOVE_CART",
        //     false
        //   );
        //   if (removeCart) {
        //     this.products = filterProductsInCart(
        //       response.products,
        //       this.$store.state.cart.cart
        //     );
        //   } else {
        //     this.products = response.products;
        //   }
        // }
        if (!this.products || this.products.length < 1) {
          this.$emit("hide");
        }
        // this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.reload();
  }
};
</script>
