<template>
  <div
    class="wordpress-slider-container"
    :class="`${customPostType}-slider`"
    v-if="
      !loading && this.config && this.proposals && this.proposals.length > 0
    "
  >
    <TitleRow
      :config="config"
      :categoryBlockName="'WordpressSlider'"
      :showMore="true"
      :isTitleFirst="false || $vuetify.breakpoint.xsOnly"
    />

    <div class="slider wordpress-slider container">
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide v-for="proposal in proposals" :key="proposal.id">
          <ProposalImage
            :proposal="proposal"
            :position="config.templateBlock.codInt"
            :eager="true"
          />
        </swiper-slide>
      </swiper>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_WordpressSlider.SHOW_BULLETS',
            true
          ) && proposals.length > 1
        "
        :id="`wordpress-slider-pagination-${config.categoryBlockId}`"
        class="swiper-pagination"
      ></div>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_WordpressSlider.SHOW_ARROWS',
            false
          ) && proposals.length > 1
        "
        :id="`wordpress-slider-prev-${config.categoryBlockId}`"
        class="swiper-button-prev"
      ></div>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_WordpressSlider.SHOW_ARROWS',
            false
          ) && proposals.length > 1
        "
        :id="`wordpress-slider-next-${config.categoryBlockId}`"
        class="swiper-button-next"
      ></div>
    </div>
  </div>
</template>
<style lang="scss">
.wordpress-slider-container {
  .swiper-slide {
    height: auto;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .swiper-container {
      margin: 0 -8px;
      padding: 0 8px;
    }
    .title-text {
      font-size: 14px !important;
      line-height: 16px;
    }
  }
  &.recipe-slider {
    .proposal-image.v-card {
      max-height: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .v-card__title {
        margin-right: 70px;
      }
      .v-card__actions {
        position: absolute;
        bottom: 8px;
        right: 8px;
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        .v-card__title {
          margin-right: 0px;
        }
        .v-card__actions {
          display: none;
        }
      }
    }
  }
}
</style>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import TitleRow from "@/components/categoryBlock/elements/TitleRow.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import CMService from "~/service/cmService";

export default {
  name: "WordpressSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalImage,
    TitleRow
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      proposals: [],
      customPostType: null,
      customPostSlugs: {
        posts: "news",
        recipe: "ricette"
      },
      loading: false,
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#wordpress-slider-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#wordpress-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#wordpress-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
      if (!this.proposals || this.proposals.length < 1) {
        this.$emit("hide");
      }
      this.fetchCustomPostType();
    },
    async fetchCustomPostType() {
      this.loading = true;

      let params;
      if (this.customPostType === "posts") {
        params = [
          {
            filterName: "filter[post_type]",
            filterValue: "post"
          },
          {
            filterName: "post_type",
            filterValue: "post"
          },
          {
            filterName: "type",
            filterValue: "post"
          },
          {
            filterName: "page",
            filterValue: 1
          }
        ];
      } else if (this.customPostType === "recipe") {
        params = [
          {
            filterName: "page",
            filterValue: 1
          }
        ];
      }

      let res = await CMService.getCustomPostByFilters(
        this.customPostType,
        params,
        this.$ebsn.meta(
          this.config,
          "categoryblocktype_WordpressSlider.CUSTOM_POST_LIMIT",
          8
        )
      );

      if (res && res.data && res.data.length) {
        this.proposals = [];
        for (let i = 0; i < res.data.length; i++) {
          let proposalImageConfig = {
            id: res.data[i].id,
            categoryProposalType: { name: "ProposalImage" },
            slug: "",
            priority: 1,
            name: "",
            descr: res.data[i].title.rendered,
            content: "",
            img: res.data[i].featured_image_url,
            imgAlt: res.data[i].title.rendered,
            imgDescription: res.data[i].title.rendered,
            categoryProposedId: -1,
            clickable: false,
            link: "",
            proposalUrl: `/${this.customPostSlugs[this.customPostType]}/${
              res.data[i].slug
            }`,
            proposalUrlType: "",
            metaData: {
              category_proposal_type: {
                TEXT_ALIGN: " text-align-left ",
                TEXT_COLOR: "var(--v-white-base)",
                SHOW_BLACK_LAYER: true,
                CONTAIN: true
              }
            }
          };
          if (this.customPostType === "recipe") {
            proposalImageConfig["recipe_cook_time"] =
              res.data[i].recipe_cook_time;
            proposalImageConfig["recipe_cook_time_text"] =
              res.data[i].recipe_cook_time_text;
            proposalImageConfig["rating"] = res.data[i].rating;

            proposalImageConfig.metaData.category_proposal_type["BTN_TEXT"] =
              "Scopri";
          }
          this.proposals.push(proposalImageConfig);
        }
      }

      this.loading = false;
    }
  },
  mounted() {
    this.customPostType = this.$ebsn.meta(
      this.config,
      "categoryblocktype_WordpressSlider.CUSTOM_POST_NAME",
      "posts"
    );
    this.fetchCustomPostType();
  },
  created() {
    this.$emit("hide");
  }
};
</script>
