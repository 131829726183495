import axios from "~/plugins/axios";
import analyticsService from "~/service/analyticsService";
import CatalogService from "@/commons/service/catalogService";
import { defaultParams } from "@/commons/service/defaultParams";
export default {
  provinceList(countryId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/province-list", {
        params: {
          country_id: countryId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  searchCitiesFromCap(cap) {
    // fidelityapi API cannot be invoked in development environment (i.e. localhost),
    // so we use complete URL
    let url = `https://www.ali.it/fidelityapi/api/city/search/${cap}`;
    return axios
      .get(url)
      .then(data => {
        return data.data;
      })
      .catch(error => error);
  },
  getCountries(continentId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/country-list", {
        params: {
          continent_id: continentId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  selectAddressClass(addressId, addressClassId) {
    return axios
      .get("/ebsn/api/tigros-address-class-controller/set-address-class-id", {
        params: {
          address_id: addressId,
          address_class_id: addressClassId
        }
      })
      .then(data => {
        return data.data;
      });
  },
  userPromoItems() {
    return axios
      .get("/ebsn/api/ali/personal-promo/view")
      .then(response => {
        return response.data.data.value;
      })
      .catch(err => {
        console.log(err);
      });
  },
  getUserVouchers() {
    return axios
      .get("/ebsn/api/ali-registration/get_vouchers")
      .then(response => {
        return response.data.data.values;
      })
      .catch(err => {
        console.log(err);
      });
  },
  useVoucher(upc) {
    return axios
      .get(
        "/ebsn/api/cart-giftcertificate/check?code=" +
          upc +
          "&show_sectors=false"
      )
      .then(response => {
        return response.data.data.values;
      })
      .catch(err => {
        console.log(err);
      });
  },
  renderAwards(upc, upc_award, card) {
    const params = {
      upc: upc,
      upc_award: upc_award,
      card: card
    };
    return axios
      .get("/ebsn/api/awards/rendering_award", { params: params })
      .then(response => {
        return response.data.data.value;
      })
      .catch(err => {
        console.log(err);
      });
  },
  cancelAwards(upc, booking_date, warehouse_id) {
    const params = {
      upc: upc,
      booking_date: booking_date,
      warehouse_id: warehouse_id
    };
    return axios
      .get("/ebsn/api/awards/cancel_award", { params: params })
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  addProductsToList(
    items,
    storeId = 6,
    showSectors = global.config.showSectors
  ) {
    let params = {
      items: []
    };
    try {
      for (var i = 0; i < items.length; i++) {
        let product = items[i].product;

        params.items.push({
          productId: product.productId,
          quantity:
            product.productInfos.TIPOLOGIA === "Sfuso"
              ? items[i].weight
              : items[i].quantity
        });
      }
    } catch (err) {
      console.log(err);
    }

    let url =
      `/ebsn/api/smart-cart/add_prod?store_id=` +
      storeId +
      `&show_sectors=` +
      showSectors;

    return axios.post(url, params).then(data => {
      if (data && data.data) {
        analyticsService.addProductToList(items);

        // global.EventBus.$emit("success", {
        //   message: global.vm.$tc(
        //     "awards.products.productAddedToCart",
        //     params.items ? params.items.length : 0
        //   )
        // });
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  addPromoToList(products) {
    // , storeId = 8, showSectors = global.config.showSectors
    let params = {
      // store_id: storeId,
      // show_sectors: showSectors,
      items: []
    };
    try {
      for (let i = 0; i < products.length; i++) {
        // let product = items[i].product ? items[i].product : items[i];
        params.items.push({
          productId: products[i].warehousePromo.view.warehousePromoId,
          quantity: 1
        });
      }
    } catch (err) {
      console.log(err);
    }

    let url = "/ebsn/api/personal-promo/add_prod";
    return axios.post(url, params).then(data => {
      if (data && data.data) {
        analyticsService.addProductToList(products);

        global.EventBus.$emit("success", {
          message: global.vm.$tc(
            "list.productAddToList",
            params.items ? params.items.length : 0
          )
        });
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  getList(storeId, calculateStock) {
    const params = {
      store_id: storeId,
      calculate_stock: calculateStock,
      show_sectors: false
    };
    return axios
      .get("/ebsn/api/smart-cart/view", {
        params: params
      })
      .then(data => data.data.data)
      .catch(error => error);
  },
  getPromoList(parentLeafletId, storeId = 8) {
    const params = {
      parent_leaflet_id: parentLeafletId,
      store_id: storeId,
      // calculate_stock: calculateStock,
      show_sectors: false
    };

    return axios
      .get("/ebsn/api/personal-promo/view", {
        params: params
      })
      .then(data => data.data.data)
      .catch(error => error);
  },
  removeItemFromList(storeId, itemId, productId) {
    let params = {
      store_id: 6,
      items: [{ itemId: itemId, quantity: 0, productId: productId }],
      show_sectors: false
    };
    let url = `/ebsn/api/smart-cart/delete_prods?store_id=` + storeId;
    params.hash = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    return axios
      .post(url, params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          //analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  removeItemFromPromoList(productId, itemId, storeId = 8) {
    let params = {
      store_id: storeId,
      items: [{ itemId: itemId, quantity: 0, productId: productId }],
      show_sectors: false
    };
    let url = "/ebsn/api/personal-promo/delete_prods";
    params.hash = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    return axios
      .post(url, params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          //analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  confirm(storeId, addressId) {
    let url = "/ebsn/api/smart-cart/confirm";
    return axios
      .post(url, undefined, {
        params: {
          address_id: addressId,
          store_id: storeId
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  emptyList(list) {
    const params = { wishlistId: list.listId };
    // let items = [];
    // factory.forEachListItem(list, function (item) {
    //   items.push({ itemId: item.itemId, quantity: 0, weight: 0, productId: item.product.productId });
    // });
    // params.items = items;

    params.items = [];
    for (var i = 0; i < list.wishlistItems.length; i++) {
      let item = list.wishlistItems[i];

      params.items.push({
        productId: item.product.productId,
        quantity: item.quantity,
        weight: item.weight,
        itemId: item.itemId
      });
    }
    let url = `/ebsn/api/smart-cart/delete_prods?store_id=` + 6;
    return axios
      .post(url, params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          // analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  updateProductsInList(storeId, item, newQuantity) {
    const decodedQuantity = CatalogService.encodeProductQuantity(
      item.product,
      newQuantity
    );
    let url = `/ebsn/api/smart-cart/update_prods?store_id=` + storeId;
    const params = {
      items: [{ itemId: item.itemId, quantity: decodedQuantity }]
    };

    const queryParams = {
      show_sectors: false
    };

    return axios.post(url, params, { params: queryParams }).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  updatePromoInList(storeId, item, newQuantity) {
    const decodedQuantity = CatalogService.encodeProductQuantity(
      item.product,
      newQuantity
    );
    let url = `/ebsn/api/personal-promo/update_prods?store_id=` + storeId;
    const params = {
      items: [{ itemId: item.itemId, quantity: decodedQuantity }]
    };

    const queryParams = {
      show_sectors: false
    };
    return axios.post(url, params, { params: queryParams }).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  validDonationPoints(formData) {
    return axios
      .post(
        "/ebsn/api/donation-points-cami/validation_donation_points",
        formData
      )
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  donatePoints(formData) {
    return axios
      .post("/ebsn/api/donation-points-cami/donation_points", formData)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  confirmPointsDonation(token) {
    return axios
      .get(
        `/ebsn/api/donation-points-cami/confirm_donation_points?token=${token}`
      )
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  treesPlanted() {
    return axios
      .get("/ebsn/api/counter-tree/get-donation-points-tree")
      .then(response => {
        return response.data.data.value;
      })
      .catch(err => {
        console.log(err);
      });
  },
  treesCounter() {
    return axios
      .get("/ebsn/api/counter-tree/get-total-tree")
      .then(response => {
        return response.data.data.value;
      })
      .catch(err => {
        console.log(err);
      });
  },
  customPromoProducts(parameters, facets) {
    // var parameters = {
    //   page: pageNumber,
    //   page_size: pageSize,
    //   timeslotId: 0,
    //   warehouseId: 0,
    //   catalogRestrictionId: 0,
    //   deliveryWarehouseId: 0
    // };
    // if (categoryId) parameters["parent_category_id"] = categoryId;
    // if (search) parameters["q"] = search;
    for (var parameterName in facets) {
      var value = facets[parameterName];
      if (value && value.length > 0) {
        // parameters[parameterName] = facets[parameterName].reduce(
        //   (f, s) => `${f},${s}`
        // );
        parameters[parameterName] = facets[parameterName];
      }
    }
    // if (sort) parameters["sort"] = sort;
    // if (storeId) parameters["store_id"] = storeId;
    return axios
      .get("/ebsn/api/leaflet/product-search", {
        params: { ...parameters, ...defaultParams() }
      })
      .then(response => response.data.data)
      .catch(error => error);
  },
  resetPassword(email, token, password, login) {
    let url = "/ebsn/api/ali-registration/password_reset";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reset_token: token,
          password: password,
          login: login
        }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  }
};
