<template functional>
  <div :class="`class class_${props.pClass.productClassId}`">
    {{ props.pClass.name }}
  </div>
</template>

<script>
export default {
  name: "ProductClass",
  props: {
    pClass: { type: Object },
    height: { type: Number, default: 22 },
    width: { type: Number, default: 22 }
  }
};
</script>
