<template>
  <div class="response-message">
    <v-alert
      type="error"
      dense
      transition="scale-transition"
      v-for="(error, index) in errors"
      :key="index"
    >
      <i18n v-if="error.code" :path="error.code" tag="span">{{
        error.message
      }}</i18n>
      <span v-else>{{ error.message || error.error }}</span>

      <a
        v-if="error.code == 'error.login.not_confirmed'"
        @click="$emit('resend_activation')"
        class="text--white text--underline"
      >
        Clicca qui per invartela una nuova.
      </a>
    </v-alert>
    <v-alert
      type="warning"
      dense
      transition="scale-transition"
      v-for="(error, index) in warnings"
      :key="index"
    >
      <i18n :path="error.code" tag="span">{{ error.message }}</i18n>
    </v-alert>
    <v-alert
      type="info"
      :icon="false"
      prominent
      text
      color="primary"
      transition="scale-transition"
      v-for="(info, index) in infos"
      :key="index"
    >
      <i18n :path="error.code" tag="span">{{ error.message }}</i18n>
    </v-alert>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
export default {
  name: "ResponseMessage",
  props: { response: { type: Object, required: true } },
  computed: {
    errors() {
      return isEmpty(this.response.errorsMessage)
        ? this.response.errors
        : this.response.errorsMessage;
    },
    warnings() {
      return isEmpty(this.response.warningsMessage)
        ? this.response.warnings
        : this.response.warningsMessage;
    },
    infos() {
      return isEmpty(this.response.infosMessage)
        ? this.response.infos
        : this.response.infosMessage;
    }
  },
  methods: {
    normalize(string) {
      return string.replace(/\\n/g, "\n");
    }
  }
};
</script>
