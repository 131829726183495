import ProductVariationsSelector from "@/components/product/ProductVariationsSelector";

export default {
  computed: {
    variations() {
      if (this.product.variations) {
        let found = this.product.variations.some(
          p => p.productId === this.product.productId
        );
        if (!found) {
          this.product.variations.push(this.product);
        }
        return this.product.variations;
      } else {
        return [];
      }
    }
  },
  methods: {
    async openVariationsModal(
      buttonLabel = `${this.$t("products.addToCart")}`
    ) {
      var config = {
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        optionsToSelect: this.variations,
        selected: this.product.productId,
        buttonLabel: buttonLabel
      };
      if (global.config.optionsSelectorModal) {
        config = Object.assign(config, global.config.optionsSelectorModal);
      }
      this.dialogInstance = await this.$dialog.show(
        ProductVariationsSelector,
        config
      );
      const result = await this.dialogInstance.wait();
      if (result) {
        this.handleSelectedVariation(result);
      }
      return result;
    }
    // handleSelectedVariation(id) {
    //   console.log(id, this.product.productId);
    //   let p = this.product.variations.find(product => product.productId === id);
    //   //   let v = this.product.variations;
    //   //   this.product = p;
    //   //   this.product.variations = v;
    //   //   console.log("p", p, v);
    //   this.$emit("chageProduct", p);
    // }
  }
};
