var render = function render(_c,_vm){return _c('div',{staticClass:"d-flex flex-grow-1 qty-wrap-cnt"},[_c('div',{staticClass:"qty-wrap py-1 mr-1 rounded-sm",class:{
      'not-empty': _vm.parent.quantity > 0,
      'flex-grow-1':
        !_vm.props.isProductDetail || _vm.parent.$vuetify.breakpoint.smAndDown,
      'w-48': _vm.props.isProductDetail && _vm.parent.$vuetify.breakpoint.mdAndUp
    },on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('v-btn',{staticClass:"minus px-1",attrs:{"aria-label":"Diminuisci quantità","depressed":"","color":"transparent","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.parent.minus(
          _vm.props.updateCart,
          { infos: { ..._vm.props.selectedOptions } },
          {
            searchUid: _vm.props.product.searchUid,
            position: _vm.props.position
          }
        )}}},[_c('v-icon',{attrs:{"color":"default","small":""}},[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.parent.quantity)+_vm._s(_vm.parent.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.parent.quantityPerUnit))])]),_c('v-btn',{staticClass:"plus px-1",attrs:{"aria-label":"Aumenta quantità","small":"","depressed":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {
          _vm.parent.plus(
            _vm.props.updateCart,
            {
              infos: {
                ..._vm.props.selectedOptions
              }
            },
            {
              searchUid: _vm.props.product.searchUid,
              position: _vm.props.position
            }
          );
        }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"default","small":""}},[_vm._v("$plus")])],1)],1),(!_vm.props.isAward)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.props.showCartButton &&
            _vm.props.product.selectOptions &&
            _vm.props.product.selectOptions.length
        )?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-sm select-options-btn mr-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.parent.openOptionsModal(
            _vm.props.product.selectOptions,
            _vm.parent.$t('common.confirm')
          )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$menu")])],1):_vm._e()]}}],null,false,284373414)},[_c('span',[_vm._v("Modifica le opzioni")])]):_vm._e(),(!_vm.props.isAward)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.props.showCartButton &&
            _vm.props.product.variations &&
            _vm.props.product.variations.length
        )?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-sm select-options-btn mr-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.parent.openVariationsModal(_vm.parent.$t('common.confirm'))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$menu")])],1):_vm._e()]}}],null,false,42982031)},[_c('span',[_vm._v("Modifica le opzioni")])]):_vm._e(),(_vm.props.showCartButton)?_c('v-btn',{staticClass:"rounded-sm add-to-cart",attrs:{"depressed":"","color":_vm.props.inCart ? 'secondary' : 'primary'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$options.addToCart(_vm.parent, _vm.props)}}},[(_vm.props.isAward)?_c('span',{staticClass:"text-uppercase pa-3"},[_c('span',[_vm._v(_vm._s(_vm.parent.$t("awards.booked.premiCart")))])]):_c('v-icon',[_vm._v("$cart")]),_c('transition',{attrs:{"name":"bounce"}},[(_vm.props.inCart)?_c('v-icon',{staticClass:"in-cart-icon"},[_vm._v(" $complete ")]):_vm._e()],1),(_vm.props.showAddToCartLabel && _vm.parent.$vuetify.breakpoint.mdAndUp)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.parent.$t("products.addToCart")))]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }