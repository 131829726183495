var render = function render(_c,_vm){return _c('div',{class:`product-price my-1 ${
      _vm.props.product.warehousePromo
        ? _vm.props.product.warehousePromo.view.cssClass + ' product_promo'
        : ''
    }`},[(_vm.props.product.warehousePromo)?_c('div',{staticClass:"header",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.header)}}):_vm._e(),(_vm.props.product.productInfos.MAXACQ)?_c('span',{staticClass:"max-purchasable smaller"},[_vm._v(" max "+_vm._s(_vm.props.product.productInfos.MAXACQ)+" ")]):_vm._e(),_c('div',{staticClass:"price-block"},[(_vm.props.product.priceStandardDisplay)?_c('div',{staticClass:"old-price"},[_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceStandardDisplay, "currency"))+" "),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay))]:_vm._e()],2),_c('v-spacer'),(_vm.props.product.warehousePromo)?_c('span',{staticClass:"footer",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.footer)}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"cur-price"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceDisplay, "currency"))),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?_c('span',{staticClass:"unit-price"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay)+" ")]):_vm._e()])]),(
      _vm.props.product.warehousePromo && _vm.props.product.warehousePromo.view.bubble
    )?_c('div',{staticClass:"bubble",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.bubble)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }