var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"proposal-horizontal-btn d-flex align-center px-4",style:(_vm.cardStyle),attrs:{"rounded":"md","href":_vm.link,"outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex align-center text-center img-container py-5 rounded-md w-100"},[(_vm.proposal.metaData.category_proposal_type.CONTENT_IMAGE)?_c('embed',{staticClass:"mr-3",style:(`max-width: 100%`),attrs:{"src":_vm.proposal.metaData.category_proposal_type.CONTENT_IMAGE,"alt":_vm.proposal.imgAlt,"title":_vm.proposal.imgDescription}}):_vm._e(),(_vm.proposal.metaData.category_proposal_type.BACKGROUND_IMAGE)?_c('embed',{staticClass:"w-100 h-100 img-container-bg-img",style:(`max-width: 100%`),attrs:{"src":_vm.proposal.metaData.category_proposal_type.BACKGROUND_IMAGE,"alt":_vm.proposal.imgAlt,"title":_vm.proposal.imgDescription}}):_vm._e(),(_vm.proposal.name)?_c('div',{staticClass:"img-container-btn-label font-weight-bold",style:(`color:  ${_vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.COLOR_TEXT',
          'var(--v-white-base)'
        )}`),domProps:{"innerHTML":_vm._s(_vm.proposal.name)}}):_vm._e()]),_c('v-icon',{staticClass:"proposal-horizontal-btn-icon",style:(`color:  ${_vm.$ebsn.meta(
        _vm.proposal,
        'metaData.category_proposal_type.COLOR_TEXT',
        'var(--v-white-base)'
      )}`)},[_vm._v("$next")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }