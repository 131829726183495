<template>
  <v-card
    rounded="md"
    :href="link"
    outlined
    @click.prevent="handleLink"
    class="proposal-horizontal-btn d-flex align-center px-4"
    :style="cardStyle"
  >
    <!-- + getIconSectionStyle() -->
    <!-- :style="getIconSectionStyle()" -->
    <div
      class="d-flex align-center text-center img-container py-5 rounded-md w-100"
    >
      <embed
        v-if="proposal.metaData.category_proposal_type.CONTENT_IMAGE"
        :src="proposal.metaData.category_proposal_type.CONTENT_IMAGE"
        :alt="proposal.imgAlt"
        :title="proposal.imgDescription"
        :style="`max-width: 100%`"
        class="mr-3"
      />
      <embed
        v-if="proposal.metaData.category_proposal_type.BACKGROUND_IMAGE"
        :src="proposal.metaData.category_proposal_type.BACKGROUND_IMAGE"
        :alt="proposal.imgAlt"
        :title="proposal.imgDescription"
        :style="`max-width: 100%`"
        class="w-100 h-100 img-container-bg-img"
      />
      <div
        class="img-container-btn-label font-weight-bold"
        v-if="proposal.name"
        v-html="proposal.name"
        :style="
          `color:  ${$ebsn.meta(
            proposal,
            'metaData.category_proposal_type.COLOR_TEXT',
            'var(--v-white-base)'
          )}`
        "
      ></div>
    </div>
    <v-icon
      class="proposal-horizontal-btn-icon"
      :style="
        `color:  ${$ebsn.meta(
          proposal,
          'metaData.category_proposal_type.COLOR_TEXT',
          'var(--v-white-base)'
        )}`
      "
      >$next</v-icon
    >
  </v-card>
</template>
<style lang="scss">
.proposal-horizontal-btn {
  isolation: isolate;
  position: relative;
  svg {
    fill: inherit;
  }
  path {
    fill: inherit;
  }
  .img-container {
    &-bg-img {
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    &-btn-label {
      z-index: 2;
    }
  }
  &-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.proposal-btn {
  .img-container {
    background-color: transparent !important;
  }
}
// .img-embedded {
//   height: 40px;
//   width: 40px;
//   border-radius: 9px !important;
// }
</style>
<script>
import banner from "~/mixins/banner";
// import get from "lodash/get";

export default {
  name: "ProposalHorizontalButton",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    cardStyle() {
      let style = {};
      let backgroundColor = this.$ebsn.meta(
        this.proposal,
        "metaData.category_proposal_type.BG_COLOR",
        "var(--v-white-base)"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      return style;
    }
  },
  methods: {
    // imgSrc(contentImage = true) {
    //   let prop =
    //     "metaData.category_proposal_type." +
    //     (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
    //   return get(
    //     this.proposal,
    //     prop,
    //     contentImage
    //       ? this.proposal.metaData.category_proposal_type.CONTENT_IMAGE
    //       : this.proposal.metaData.category_proposal_type.BACKGROUND_IMAGE
    //   );
    // }
    /*getIconSectionStyle() {
      let style = {};
      let backgroundColor = this.$ebsn.meta(
        this.proposal,
        "metaData.category_proposal_type.BG_ICON_COLOR"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
        style.borderTopColor = backgroundColor;
      }
      return style;
    }*/
  }
};
</script>
