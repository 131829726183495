var render = function render(_c,_vm){return _c('div',{staticClass:"title-row d-flex flex-row justify-md-space-around align-center mb-5"},[(_vm.props.isTitleFirst)?_c('v-divider',{staticClass:"primary thick mr-1 mr-sm-3"}):_vm._e(),(_vm.props.isTitleFirst)?_c('div',{staticClass:"title-section d-flex align-start align-sm-center flex-column flex-sm-row"},[_c('ebsn-meta',{attrs:{"target":_vm.props.config,"path":`categoryblocktype_${_vm.props.categoryBlockName}.TITLE`,"tag":"h2","cssClass":"slider-title font-weight-bold mr-1 mr-sm-3"}}),_c('ebsn-meta',{attrs:{"target":_vm.props.config,"path":`categoryblocktype_${_vm.props.categoryBlockName}.SUBTITLE`,"tag":"span","cssClass":"subtitle"}})],1):_vm._e(),_c('v-divider',{staticClass:"primary thick ml-0 ml-sm-3"}),(
      _vm.props.showMore &&
        !_vm.parent.$ebsn.meta(
          _vm.props.config,
          'categoryblocktype_' + _vm.props.categoryBlockName + '.HIDE_LINK'
        )
    )?_c('v-btn',{staticClass:"align-self-center mx-sm-3",attrs:{"color":"primary","depressed":"","to":_vm.$options.calcLinkToGo(
        _vm.props.config,
        _vm.props.categoryBlockName,
        _vm.parent.$ebsn.meta
      )}},[_vm._v(" "+_vm._s(_vm.parent.$ebsn.meta( _vm.props.config, "categoryblocktype_" + _vm.props.categoryBlockName + ".BUTTON_TEXT", _vm.parent.$t("categoryBlock.productListSlider.showAll") ))+" ")]):_vm._e(),(!_vm.props.isTitleFirst)?_c('v-divider',{staticClass:"primary thick mr-1 mr-sm-3"}):_vm._e(),(!_vm.props.isTitleFirst)?_c('div',{staticClass:"d-flex align-center mr-1 mr-sm-3"},[_c('ebsn-meta',{attrs:{"target":_vm.props.config,"path":`categoryblocktype_${_vm.props.categoryBlockName}.TITLE`,"tag":"h2","cssClass":"slider-title font-weight-bold mr-1 mr-sm-3"}}),_c('ebsn-meta',{attrs:{"target":_vm.props.config,"path":`categoryblocktype_${_vm.props.categoryBlockName}.SUBTITLE`,"tag":"span","cssClass":"subtitle"}})],1):_vm._e(),_c('v-divider',{staticClass:"primary thick"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }