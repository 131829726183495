<template functional>
  <div
    class="title-row d-flex flex-row justify-md-space-around align-center mb-5"
  >
    <v-divider class="primary thick mr-1 mr-sm-3" v-if="props.isTitleFirst" />
    <div
      class="title-section d-flex align-start align-sm-center flex-column flex-sm-row"
      v-if="props.isTitleFirst"
    >
      <ebsn-meta
        :target="props.config"
        :path="`categoryblocktype_${props.categoryBlockName}.TITLE`"
        tag="h2"
        cssClass="slider-title font-weight-bold mr-1 mr-sm-3"
      />
      <ebsn-meta
        :target="props.config"
        :path="`categoryblocktype_${props.categoryBlockName}.SUBTITLE`"
        tag="span"
        cssClass="subtitle"
      />
    </div>
    <v-divider class="primary thick ml-0 ml-sm-3" />
    <v-btn
      v-if="
        props.showMore &&
          !parent.$ebsn.meta(
            props.config,
            'categoryblocktype_' + props.categoryBlockName + '.HIDE_LINK'
          )
      "
      color="primary"
      depressed
      class="align-self-center mx-sm-3"
      :to="
        $options.calcLinkToGo(
          props.config,
          props.categoryBlockName,
          parent.$ebsn.meta
        )
      "
    >
      {{
        parent.$ebsn.meta(
          props.config,
          "categoryblocktype_" + props.categoryBlockName + ".BUTTON_TEXT",
          parent.$t("categoryBlock.productListSlider.showAll")
        )
      }}
    </v-btn>
    <v-divider class="primary thick mr-1 mr-sm-3" v-if="!props.isTitleFirst" />
    <div class="d-flex align-center mr-1 mr-sm-3" v-if="!props.isTitleFirst">
      <ebsn-meta
        :target="props.config"
        :path="`categoryblocktype_${props.categoryBlockName}.TITLE`"
        tag="h2"
        cssClass="slider-title font-weight-bold mr-1 mr-sm-3"
      />
      <ebsn-meta
        :target="props.config"
        :path="`categoryblocktype_${props.categoryBlockName}.SUBTITLE`"
        tag="span"
        cssClass="subtitle"
      />
    </div>
    <v-divider class="primary thick" />
  </div>
</template>
<style lang="scss">
.title-row {
  .title-section {
    position: relative;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .subtitle {
      position: absolute;
      width: max-content;
      top: 36px;
    }
  }
}
</style>
<script>
export default {
  name: "TitleRow",
  props: {
    config: { type: Object, required: true },
    categoryBlockName: { type: String, required: true },
    showMore: { type: Boolean, default: false },
    isTitleFirst: { type: Boolean, default: true }
  },
  calcLinkToGo(config, categoryBlockName, getMetaFunction) {
    let categorySlug = getMetaFunction(
      config,
      "categoryblocktype_" + categoryBlockName + ".CATEGORY.slug"
    );
    if (categorySlug) {
      return {
        name: "Category",
        params: {
          pathMatch: categorySlug
        }
      };
    } else {
      return getMetaFunction(
        config,
        "categoryblocktype_" + categoryBlockName + ".LINK"
      );
    }
  }
};
</script>
