import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";
import "@/scss/_fonts.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        logo: {
          default: "/logo/logo.svg",
          mobile: "/logo/logo-mobile.svg"
        },
        default: {
          base: "#000",
          lighten1: "#534741"
        },
        primary: {
          base: "#FD7E14",
          lighten1: "#FFA826",
          lighten2: "#FFE4C2",
          darken1: "#CE6905"
        },
        secondary: {
          base: "#736962",
          lighten1: "#92877f",
          darken1: "#48423e"
        },
        accent: { base: "#E9DDD4" },
        white: "#FFFFFF",
        grey: {
          base: "#A4A3AF",
          lighten1: "#F5F2EB",
          lighten2: "#E5E5E5",
          lighten3: "#FBFAF7",
          lighten4: "#E6E6E6",
          lighten5: "#E2E2E2",
          darken1: "#454545",
          darken2: "#5A5A5A"
        },
        red: {
          base: "#DA1B5C"
        },
        green: {
          base: "#61A31E",
          lighten1: "#87B22E",
          lighten2: "#DCF4BC",
          darken1: "#257B25",
          darken2: "#3DAB62"
        },
        blue: {
          base: "#CFE2FF"
        },
        brown: {
          base: "#E8DCC4"
        },
        yellow: {
          base: "#FFCD38",
          lighten1: "#FFE69C",
          lighten2: "#FFE69C",
          darken1: "#FFC007"
        },
        promo: "#FDCC00",
        promo1: "#EE1D22",
        warning: "#ff8484"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      myLocation: "icon-my-location",
      map: "icon-map",
      schedule: "icon-schedule",
      call: "icon-call",
      arrowBack: "icon-arrow-back",
      arrowDownward: "icon-arrow-downward",
      arrowForward: "icon-arrow-forward",
      calendar: "icon-calendar",
      fastSearch: "icon-fast-search",
      location: "icon-location",
      arrowTopLeft: "icon-arrow-top-left",
      buoni: "icon-buoni",
      cart: "icon-cart",
      chevronDown: "icon-chevron-down",
      chevronLeft: "icon-chevron-left",
      chevronRight: "icon-chevron-right",
      chevronUp: "icon-chevron-up",
      creditCard: "icon-credit-card",
      eye: "icon-eye-full",
      eyeOff: "icon-eye-off-full",
      facebook: "icon-facebook",
      filter: "icon-filter",
      heart: "icon-heart",
      heartfull: "icon-heartfull",
      indirizzi: "icon-indirizzi",
      instagram: "icon-instagram",
      lavoraConNoi: "icon-lavora-con-noi",
      linkedin: "icon-linkedin",
      minus: "icon-minus",
      mondoAli: "icon-mondo-ali",
      negoziVolantini: "icon-negozi-volantini",
      news: "icon-news",
      offerta: "icon-offerta",
      parlaConNoi: "icon-parla-con-noi",
      plus: "icon-plus",
      premiali: "icon-premiali",
      profile: "icon-profile",
      search: "icon-search",
      servizioSlot: "icon-servizio-slot",
      settings: "icon-settings",
      sociale: "icon-sociale",
      sostenibilita: "icon-sostenibilita",
      star: "icon-star-outline",
      starFull: "icon-star",
      twitter: "icon-twitter",
      tiktok: "icon-tiktok",
      youtube: "icon-youtube",
      magazine: "icon-magazine",
      //icone standard vuetify da condificare sempre
      complete: "icon-complete",
      cancel: "icon-close",
      close: "icon-close",
      delete: "icon-delete",
      clear: "icon-close",
      success: "icon-complete",
      info: "icon-info",
      warning: "icon-warning",
      error: "icon-warning",
      prev: "icon-chevron-left",
      next: "icon-chevron-right",
      checkboxOff: "icon-checkbox-off",
      checkboxOn: "icon-checkbox-on",
      checkboxIndeterminate: "...",
      delimiter: "...", // for carousel
      sort: "...",
      expand: "icon-chevron-down",
      menu: "icon-menu",
      subgroup: "...",
      dropdown: "icon-chevron-down",
      radioOn: "icon-radio-checked",
      radioOff: "icon-radio-unchecked",
      edit: "icon-edit",
      print: "icon-print",
      avatar: "icon-avatar",
      pin_color: "icon-pin_color",
      premiali_orange: "icon-premiali_orange",
      fidelity: "icon-fidelity",
      home: "icon-home",
      ratingEmpty: "...",
      ratingFull: "...",
      ratingHalf: "...",
      loading: "...",
      first: "...",
      last: "...",
      unfold: "...",
      file: "...",
      signal: "icon-signal-strong-regular",
      barcode: "icon-barcode-scan",
      give: "icon-give",
      dots: "icon-vertical-dots"
    }
  }
});
