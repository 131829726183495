<template>
  <div class="timeslot-selector">
    <div
      class="d-flex flex-column align-center justify-center"
      v-if="category && category.metaData && category.metaData.category_info"
    >
      <h2 class="selector-title secondary--text text-center">
        {{ category.metaData.category_info.TITLE }}
      </h2>
      <h5
        v-if="category.metaData.category_info.SUBTITLE"
        class="subtitle-1 text-center"
      >
        {{ category.metaData.category_info.SUBTITLE }}
      </h5>
      <div
        class="text-body-2 text-sm-body-1 font-weight-bold text-center"
        v-if="category.metaData.category_info.DESCRIPTION"
        v-html="category.metaData.category_info.DESCRIPTION"
      ></div>

      <p
        class="selector-subtitle text-body-2 text-sm-body-1 secondary--text text-center"
      >
        <strong>
          {{
            $t(
              `timeslots.${
                cart.shippingAddress.addressType === "home"
                  ? "deliveryAddress"
                  : "pickupAddress"
              }`
            )
          }}:
        </strong>
        {{
          $t(
            "navbar.address." + cart.shippingAddress.addressTypeId + ".list",
            cart.shippingAddress
          )
        }}
        <!-- <v-icon small color="primary" right @click="changeAddress">
            $edit
          </v-icon> -->
      </p>

      <p
        class="selector-warning selector-warning-under-general-warning text-center"
        v-if="
          timeslotRestrictions.underLeadTimeItems ||
            timeslotRestrictions.dayLock ||
            timeslotRestrictions.lockCutoff
        "
      >
        {{ $t("timeslots.generalWarning") }}
      </p>
      <category-block :category="category" position="position1" />
    </div>

    <v-divider></v-divider>
    <v-tabs
      hide-slider
      icons-and-text
      v-model="tab"
      :height="$vuetify.breakpoint.smAndDown ? 70 : 100"
      center-active
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <div v-for="timeslotDay in days" :key="timeslotDay.dateIso">
        <div class="promo-pill">
          <span
            class="summary-text px-2 py-1 white--text text-uppercase red-promo rounded-pill"
            v-show="showTimeslotsPromo(timeslotDay.timeslots)"
            >PROMO</span
          >
        </div>
        <v-tab @click="selectTimeslotDay(timeslotDay)">
          <div
            class="time-div d-flex flex-column align-center justify-space-around"
          >
            <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
              <span class="day-number font-weight-bold">
                OGGI
              </span>
            </template>
            <template v-else>
              <span class="day-string">
                {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
              </span>
              <span class="day-number font-weight-bold">
                {{ $dayjs(timeslotDay.dateIso).format("D") }}
              </span>
              <span class="month">
                {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
              </span>
            </template>
          </div>
        </v-tab>
      </div>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item
        class="timeslot-container"
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
      >
        <v-row v-if="timeslotDay.timeslots.length > 0" no-gutters>
          <v-col
            :cols="timeslot.expanded ? 12 : 6"
            :md="timeslot.expanded ? 12 : 4"
            :order="timeslot.expanded ? 0 : 1"
            v-for="timeslot in timeslotDay.timeslots"
            :key="timeslot.timeslotId"
          >
            <div
              class="promo-name-pill"
              v-if="
                !timeslot.expanded && showTimeslotsPromo(timeslotDay.timeslots)
              "
            >
              <span
                class="summary-text px-2 py-1 white--text red-promo promo-name rounded-pill"
                v-if="showTimeslotPromo(timeslot)"
                >{{ timeslot.promoName }}</span
              >
              <span
                v-else-if="showStandardDeliveryTag(timeslot)"
                class="summary-text white--text standard-delivery promo-name"
                >Consegna standard</span
              >
            </div>
            <v-hover v-slot="{ hover }">
              <div
                v-on:click="selectTimeslot(timeslot)"
                class="timeslot-button d-flex justify-center align-left flex-row"
                :class="[
                  timeslot.status,
                  { 'under-lead-time': timeslot.underLeadTime },
                  { 'under-lock-day': timeslot.underLockDay },
                  { 'under-lock-cutoff': timeslot.underLockCutoff },
                  { selected: timeslot.selected },
                  {
                    'expanded-card': timeslot.expanded
                  },
                  {
                    'bottom-border-radius': showTimeslotsPromo(
                      timeslotDay.timeslots
                    )
                  }
                ]"
              >
                <div class="level"></div>
                <div class="d-flex flex-column " style="flex-grow:1">
                  <span
                    class="timeslot-time font-weight-bold align-start text-center"
                    :class="{
                      'mb-4': timeslot.expanded && timeslot.status === 'full'
                    }"
                    >{{ timeslot.beginTime }} - {{ timeslot.endTime }}</span
                  >

                  <span
                    class="text-status font-weight-bold text-center"
                    v-if="timeslot.status === 'intermediate'"
                  >
                    1 posto disponibile
                  </span>
                  <span
                    v-if="timeslot.status === 'full'"
                    class="text-status  text-center font-weight-bold"
                  >
                    FASCIA ESAURITA
                  </span>
                  <span
                    v-show="showCountdown(timeslot, hover)"
                    class="text-status text-none text-left countdown"
                    v-html="getEndHuman(timeslot)"
                  />
                </div>
              </div>
            </v-hover>
          </v-col>
        </v-row>
        <p v-else>
          {{ $t("timeslots.noSlots") }}
        </p>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="legend" no-gutters>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend justify-start justify-sm-center">
          <div class="marker empty"></div>
          <span>Disponibile</span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend justify-start justify-sm-center">
          <div class="marker intermediate"></div>
          <span>1 posto disponibile</span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend justify-start justify-sm-center">
          <div class="marker full"></div>
          <span>Non disponibile</span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend justify-start justify-sm-center">
          <div class="marker selected"></div>
          <span>Selezionata</span>
        </div>
      </v-col>
    </v-row>
    <category-block :category="category" position="position2" />
  </div>
</template>
<style scoped lang="scss">
.timeslot-selector {
  height: 100%;
  .selector-title {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 20px;
    }
  }
  .selector-subtitle {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 14px;
    }
  }

  .v-tabs {
    flex-grow: unset;
  }
  .v-slide-group__wrapper {
    background-color: white;
  }
  .v-divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  // .title {
  //   font-size: 25px;
  // }
  // .subtitle {
  //   font-size: 16px;
  // }
  .timeslot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .legend {
    flex-grow: unset;
    margin-top: 40px;
  }
  .timeslot-card {
    overflow: hidden;
    justify-content: center;
  }
  .timeslot-button {
    min-height: 85px;
    padding: 0 5px;
    border-radius: 6px;
    background-color: var(--v-grey-lighten2);
    // border: 1px solid #e5e5e5;
    margin: 0px 4px 6px 4px;
    font-size: 15px;
    font-weight: normal;
    pointer-events: auto;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: $text-color;
    .level {
      content: "&nbsp;";
      display: block;
      border-radius: 50%;
      padding: 16px;
    }
    &.empty {
      .level {
        background-color: #52ab41;
      }
    }
    &.intermediate {
      .level {
        background-color: #faffb7;
      }
    }
    &.full {
      .level {
        background-color: #f07d7e;
      }
    }

    .timeslot-time {
      font-size: 18px;
      text-transform: uppercase;
    }
    .text-status {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .timeslot-legend {
    margin: 3px;
    display: flex;
    align-items: center;

    .marker {
      width: 32px;
      height: 32px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      border: 1px solid #b1b0af;
      border-radius: 4px;
    }
    .marker.empty {
      background-color: #52ab41;
    }
    .marker.intermediate {
      background-color: #faffb7;
    }
    .marker.full {
      background-color: #f07d7e;
    }
    span {
      vertical-align: middle;
      font-size: 14px;
      font-weight: bold;
      color: $text-color;
    }
  }

  .selected {
    color: #fff;
    background-color: var(--v-primary-base);
  }
  .v-tab {
    border: 1px solid var(--v-primary-base);
    color: var(--v-primary-base);
    padding: 0px !important;
    margin: 0px 5px 0px 5px !important;
    border-radius: 5px;
    min-width: 95px;
    min-height: 100px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      min-width: 70px;
      min-height: 70px;
      height: 70px;
    }
    .time-div {
      color: $text-color;
      padding: 0px;
      margin: 0px !important;
      .day-string {
        margin-bottom: 5px;
        font-size: 14px;
        letter-spacing: 0.1px;
      }
      .day-number {
        font-size: 29px;
        letter-spacing: 0.17px;
      }
      .month {
        margin-top: 5px;
        font-size: 14px;
        letter-spacing: 0.1px;
      }
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .day-string {
          margin-bottom: 5px;
          font-size: 12px;
          letter-spacing: 0.1px;
        }
        .day-number {
          font-size: 16px;
          letter-spacing: 0.17px;
        }
        .month {
          margin-top: 5px;
          font-size: 12px;
          letter-spacing: 0.1px;
        }
      }
    }
  }
  .v-tab.v-tab--active {
    border-radius: 5px;
    color: $white;
    background-color: var(--v-primary-base);
    .time-div {
      color: $white;
    }
  }

  .v-slide-group__content {
    height: 100%;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 20px;
    flex: 0 0 25px;
  }
  .timeslot-selector {
    .timeslot-button {
      padding: 10px 5px;
      .timeslot-time {
        font-size: 15px;
      }
      .timeslot-status {
        font-size: 10px;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
import { forEachCartItem } from "~/service/ebsn";
import { mapState } from "vuex";
import toNumber from "lodash/toNumber";
import isNumber from "lodash/isNumber";
import split from "lodash/split";
import isEqual from "lodash/isEqual";
import get from "lodash/get";

export default {
  name: "TimeslotSelector",

  data() {
    return {
      days: {},
      tab: 0,
      selectedTimeslotDay: {},
      disabled: false,
      category: {},
      timeslotPromo: [],
      timeslotPromoSelected: {}
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    timeslotRestrictions() {
      var result = {};
      let maxLeadTime = 0;
      forEachCartItem(this.cart, item => {
        let leadTime = toNumber(item.product.productInfos.LEAD_TIME);
        if (isNumber(leadTime) && leadTime > maxLeadTime) {
          maxLeadTime = leadTime;
        }
        result.underLeadTimeItems = maxLeadTime > 0;

        if (item.product.productInfos.DAY_LOCK) {
          if (!result.dayLock) result.dayLock = [];
          let array = split(item.product.productInfos.DAY_LOCK, " ");
          if (item.product.productInfos.DAY_LOCK.indexOf(",") > -1) {
            array = split(item.product.productInfos.DAY_LOCK, ",");
          }
          for (let i = 0; i < array.length; i++) {
            if (array[i].indexOf("_") > -1) {
              // "day_deliveryDayPeriodId, es: lun_2"
              let daySplitted = array[i].split("_");
              result.dayLock.push({
                day: daySplitted[0],
                deliveryDayPeriodId: daySplitted[1]
              });
            } else {
              // "day, es: lun"
              result.dayLock.push({
                day: array[i],
                deliveryDayPeriodId: undefined
              });
            }
          }
        }

        if (item.product.productInfos.PRODUCT_LOCK_CUTOFF) {
          if (!result.lockCutoff) result.lockCutoff = [];
          let arrayLock = split(
            item.product.productInfos.PRODUCT_LOCK_CUTOFF,
            " "
          );
          if (item.product.productInfos.PRODUCT_LOCK_CUTOFF.indexOf(",") > -1) {
            arrayLock = split(
              item.product.productInfos.PRODUCT_LOCK_CUTOFF,
              ","
            );
          }
          // arrayLock[0] = "16:00-18:00";
          for (var i = 0; i < arrayLock.length; i++) {
            let hours = arrayLock[i].split("-");
            result.lockCutoff.push(hours);
          }
        }
      });
      result.leadTimeLimit = this.$dayjs().add(maxLeadTime, "hour");
      // result.dayLock = null;
      return result;
    }
  },
  methods: {
    async openAddressSelector() {
      if (await this.setAddress()) {
        await this.needTimeslot();
      }
    },
    showCountdown(timeslot, hover) {
      return (
        timeslot.status !== "full" &&
        !timeslot.selected &&
        (hover || this.isMobile)
      );
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    getEndHuman(timeslot) {
      var a = this.$dayjs(
        timeslot.cutoffDate + " " + timeslot.cutoffTime,
        "DD/MM/YYYY hh:mm"
      );
      var d = this.$dayjs.duration(a.diff(this.$dayjs()));
      var durationString =
        (d.days() > 0 ? d.days() + " giorni " : "") +
        (d.hours() > 0 ? d.hours() + " ore " : "") +
        (d.minutes() > 0 ? d.minutes() + " minuti" : "");
      return `ordina entro: <b>${durationString}</b>`;
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(false);

      if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
        _this.days.shift();
      }

      for (var d = 0; d < _this.days.length; d++) {
        for (var t = 0; t < _this.days[d].timeslots.length; t++) {
          var timeslot = _this.days[d].timeslots[t];
          //TEST
          if (
            timeslot.metaData &&
            timeslot.metaData.timeslot_info &&
            timeslot.metaData.timeslot_info.EXPAND
          ) {
            timeslot.expanded = true;
          } else {
            timeslot.expanded = false;
          }
          if (
            timeslot.userGiftCertificates &&
            timeslot.userGiftCertificates.length > 0
          ) {
            for (var g = 0; g < timeslot.userGiftCertificates.length; g++) {
              if (
                timeslot.userGiftCertificates[g].giftCertificate &&
                timeslot.status != "full"
              ) {
                this.timeslotPromo.push(timeslot);
              }
            }
          }
        }
      }

      for (var i = 0; i < _this.days.length; i++) {
        console.log("NEL FOR!");
        console.log(_this.days[i].dateIso);
        console.log(_this.cart.timeslot.date);
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }
      return false;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "timeslot-selector"
        );
      } catch (e) {
        console.log(e);
      }
    },
    showTimeslotsPromo(timeslots) {
      var result = false;
      for (var i = 0; i < timeslots.length; i++) {
        var timeslot = timeslots[i];
        for (var k = 0; k < this.timeslotPromo.length; k++) {
          if (isEqual(timeslot, this.timeslotPromo[k])) {
            result = true;
            break;
          }
        }
        if (result) {
          break;
        }
      }
      return result;
    },
    showTimeslotPromo(timeslot) {
      var result = false;
      if (
        timeslot.metaData &&
        timeslot.metaData.timeslot_info &&
        timeslot.metaData.timeslot_info.EXPAND
      ) {
        timeslot.expanded = true;
      } else {
        timeslot.expanded = false;
      }
      for (var k = 0; k < this.timeslotPromo.length; k++) {
        if (isEqual(timeslot, this.timeslotPromo[k])) {
          result = true;
          var time = timeslot;
          for (var g = 0; g < time.userGiftCertificates.length; g++) {
            timeslot.promoName =
              time.userGiftCertificates[g].giftCertificate.name;
          }
        }
      }

      return result;
    },
    showStandardDeliveryTag(timeslot) {
      return get(
        timeslot,
        "metaData.timeslot_info.SHOW_STANDARD_DELIVERY_TAG",
        false
      );
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
  }
};
</script>
