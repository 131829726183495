<template>
  <v-card
    class="text-decoration-none black rounded-md px-4 py-3 d-flex justify-space-between align-center"
    :to="{
      name: 'Awards'
    }"
  >
    <div class="d-flex flex-column">
      <span class="white--text font-weight-bold">{{
        $t("dashboard.fidelityPoints.title")
      }}</span>
      <div class="d-flex mt-1">
        <span
          class="primary--text font-weight-bold mr-3"
          v-if="availablePoints"
          >{{ availablePoints }}</span
        >
        <span class="white--text">{{
          $t("dashboard.fidelityPoints.label")
        }}</span>
      </div>
    </div>
    <v-icon color="primary">$chevronRight</v-icon>
  </v-card>
</template>

<script>
import categoryBlockType from "./categoryBlockType";
import cartInfo from "~/mixins/cartInfo";
import { mapState } from "vuex";
export default {
  name: "UserPoints",
  mixins: [cartInfo, categoryBlockType],
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    availablePoints() {
      return (
        this.cart &&
        this.cart.userPoints &&
        this.cart.userPoints.CAMI &&
        this.cart.userPoints.CAMI.pointsAvailable
      );
    }
  }
};
</script>
