global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: false,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 1,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  domainWhitelist: [
    "aliperme.it",
    "test.aliperme.it",
    "www.aliperme.it",
    "alisupermercati.it",
    "test.alisupermercati.it",
    "www.alisupermercati.it",
    "dev-ali.digitelematica.org",
    "localhost",
    "app.alisupermercati.it"
  ],
  showInPopupCode: ["msg.cart.availability.errors"],
  dialogs: [],
  pushNotificationEnabled: true,
  urlScheme: "ebsnali://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/profile/orders/:orderId": "/profile/orders/:orderId",
    "/checkout": "/checkout",
    "/search": "/search",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/page/:slug": "/page/:slug",
    "/recover-password-confirm": "/recover-password-confirm",
    "/reset-password-request": "/reset-password-request",
    "/reset-password-sent": "/reset-password-sent",
    "/reset-password": "/reset-password",
    "/reset-password-completed": "/reset-password-completed",
    "/registration/confirm": "/registration/confirm",
    "/registration/thankyou": "/registration/thankyou",
    "/registration/card-agreement": "/registration/card-agreement"
  },
  recaptchaTokenEnabled: true,
  recaptchaEnabledEndpoints: ["/ebsn/api/auth/login"],
  // filterProductClasses: [1, 2, 3, 4, 6],
  updateCart: false,
  buildItemsMap: true,
  dialogMaxWidth: 530,
  addToFavoritesWidth: 350,
  analyticsClass: "analyticsServiceGTMAli",
  cartItemInfos: ["size", "customWeight"],
  acceptAlternatives: true,
  productVoteMaxRating: 5,
  categoryTreeDepth: 4,
  categoryPrefix: "",
  optionsSelectorModal: { mode: "radio", radioRow: false },
  customCountries: [
    {
      itemId: "1",
      iso: "IT",
      name: "Italia"
    },
    {
      itemId: "211",
      iso: "CH",
      name: "Svizzera"
    }
  ],
  // facebookClientId: "1250974205313975",
  // googleClientId:
  //   "1061314893204-tbicvnnsq74cjiarigmq3jiilqu56b0v.apps.googleusercontent.com",
  perksWarehouseId: 42,
  perksCatalogRestrictionId: 1,
  perksCategoryId: 148000574,
  showProductsByDate: {
    show: true,
    minDate: "2022-07-04",
    maxDate: "2023-07-31"
  },
  defaultPaymentTypeId: 12,
  socialLogo: "/logo/social.png",
  defaultStoreName: "Catalogo Globale",
  customWishlistDelete: true
};
