<template>
  <v-card flat elevation="0" class="product-option-selector pa-3 pa-sm-6">
    <h3 class="text-center my-3" v-if="showTitle">
      Seleziona le opzioni
    </h3>
    <div>
      <v-radio-group
        v-if="mode === 'radio'"
        dense
        v-model="selectedOption"
        :row="radioRow"
        @change="optionChanged(selectedOption)"
      >
        <v-radio
          :value="opt.productId"
          v-for="opt in optionsArray"
          :key="opt.name"
          ><template v-slot:label>
            <span class="">{{ opt.name }}</span>
          </template></v-radio
        >
      </v-radio-group>
      <v-select
        v-if="mode === 'select'"
        outlined
        dense
        v-model="selectedOption"
        :items="optionsArray"
        :label="optionsArray.name"
        item-text="name"
        item-value="productId"
        @change="optionChanged(selectedOption)"
      />
    </div>
    <v-card-actions class="d-flex flex-row justify-center">
      <v-btn
        class="secondary"
        text
        @click="addToCart"
        :disabled="selectedOption == null"
      >
        {{ buttonLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductVariationsSelector",
  data() {
    return {
      selectedOption: null,
      optionsArray: []
    };
  },
  props: {
    optionsToSelect: { type: Array, required: true },
    selected: { type: Number },
    mode: { type: String, default: "select" },
    showTitle: { type: Boolean, default: true },
    buttonLabel: { type: String },
    radioRow: { type: Boolean, default: true }
  },
  computed: {},
  methods: {
    optionChanged(option) {
      this.$emit("option-changed", option);
    },
    addToCart() {
      this.$emit("submit", this.selectedOption);
    }
  },
  mounted() {
    this.optionsArray = cloneDeep(this.optionsToSelect);
    if (this.selected) {
      this.selectedOption = this.selected;
    }
  }
};
</script>
