var render = function render(){var _vm=this,_c=_vm._self._c;return (
    !_vm.loading && this.config && this.proposals && this.proposals.length > 0
  )?_c('div',{staticClass:"wordpress-slider-container",class:`${_vm.customPostType}-slider`},[_c('TitleRow',{attrs:{"config":_vm.config,"categoryBlockName":'WordpressSlider',"showMore":true,"isTitleFirst":false || _vm.$vuetify.breakpoint.xsOnly}}),_c('div',{staticClass:"slider wordpress-slider container"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.proposals),function(proposal){return _c('swiper-slide',{key:proposal.id},[_c('ProposalImage',{attrs:{"proposal":proposal,"position":_vm.config.templateBlock.codInt,"eager":true}})],1)}),1),(
        _vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_WordpressSlider.SHOW_BULLETS',
          true
        ) && _vm.proposals.length > 1
      )?_c('div',{staticClass:"swiper-pagination",attrs:{"id":`wordpress-slider-pagination-${_vm.config.categoryBlockId}`}}):_vm._e(),(
        _vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_WordpressSlider.SHOW_ARROWS',
          false
        ) && _vm.proposals.length > 1
      )?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":`wordpress-slider-prev-${_vm.config.categoryBlockId}`}}):_vm._e(),(
        _vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_WordpressSlider.SHOW_ARROWS',
          false
        ) && _vm.proposals.length > 1
      )?_c('div',{staticClass:"swiper-button-next",attrs:{"id":`wordpress-slider-next-${_vm.config.categoryBlockId}`}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }