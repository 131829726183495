<template>
  <transition name="flip">
    <div
      class="socioCard pa-3 my-2 my-sm-0"
      :key="expandedBarcode"
      @click="expandedBarcode = !expandedBarcode"
    >
      <div class="user">{{ user.firstName }} {{ user.lastName }}</div>
      <div class="logo">
        <img
          src="/img_layout/registration/carta-fedelta-conferma.jpg"
          alt="Logo Fidelity"
          width="100%"
        />
      </div>
      <div class="barcode" :class="{ expanded: expandedBarcode }">
        <barcode :value="user.fidelityCard" class="fidelity-ean">
          Error
        </barcode>
      </div>
      <div class="desc">
        <div>Tessera N°</div>
        <div>
          <strong>{{ user.fidelityCard }}</strong>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss">
.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
.socioCard {
  margin: 0px !important;
  padding-top: 0px !important;
  cursor: pointer;
  height: 180px;
  border-radius: 8px;
  background-color: var(--v-primary-lighten1);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 50px 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  .logo {
    img {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-width: 40%;
      }
      max-width: 30%;
    }
  }
}
.barcode {
  g {
    rect,
    text {
      fill: var(--v-default-base) !important;
    }
  }
}
.barcode {
  grid-area: 2 / 2 / 3 / 3;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: $white;
  display: flex;
  max-height: 90px;
  transition: 500ms;
  .fidelity-ean {
    width: 100%;
    height: 100%;
    max-height: 116px;
    .vue-barcode-element {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}
.barcode.expanded {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 1;
  transition: 500ms;
  max-height: 100%;
  .fidelity-ean {
    max-height: fit-content;
  }
}
.user {
  grid-area: 1 / 1 / 2 / 2;
  font-size: 13px;
  font-weight: bold;
  color: $text-color;
}
.logo {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: center;
  img {
    max-width: 150px;
  }
}
.desc {
  grid-area: 2 / 1 / 3 / 2;
  text-transform: uppercase;
  color: $text-color;
}
.fidelity-card-row {
  .container {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
}
</style>
<script>
// @ is an alias to /src
//import RegistrationService from "~/service/userService";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FidelityCardRow",
  props: {
    drawer: { type: Boolean, default: false },
    showTitle: { type: Boolean, default: true },
    showSubtitle: { type: Boolean, default: true },
    showCard: { type: Boolean, default: true },
    toggleCard: { type: Boolean, default: false }
  },
  data() {
    return {
      // user: null,
      expandedBarcode: false
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },

  //async created() {
  //await this.fetchUserData();
  //if (this.isCordova && window.cordova) {
  // global.EventBus.$on("closeFidelityDialog", () => {
  //   // eslint-disable-next-line no-undef
  //   cordova.plugins.brightness.setBrightness(this.oldBrightness);
  // });
  // }
  //},

  methods: {
    /*async fetchUserData() {
      RegistrationService.getUserDetail().then(user => {
        this.user = user;
      });
    },*/
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  },
  mounted() {
    var _this = this;
    if (this.toggleCard) {
      _this.expandedBarcode = true;
      if (_this.isCordova && window.cordova) {
        //eslint-disable-next-line no-undef
        cordova.plugins.brightness.setBrightness(1);
      }
    }
  }
};
</script>
