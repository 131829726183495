<template>
  <v-container fluid fill-height class="py-8">
    <v-layout>
      <v-flex xs12 class="d-flex flex-column align-center justify-center">
        <img
          src="/logo/logo.svg"
          alt="Logo Alisupermercati.it"
          :style="{ height: '90px' }"
        />
        <v-form
          ref="form"
          class="w-100"
          v-model="valid"
          @submit.prevent.stop="handleSubmit"
        >
          <v-card class="elevation-0">
            <div class="text-h1 line-height-1 default--text text-center mt-6">
              {{ $t("login.title") }}
            </div>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-text-field
                color="primary"
                v-model="email"
                :error-messages="errors"
                type="email"
                :label="$t('login.email')"
                autocomplete="off"
                class="rounded-lg"
                required
                :rules="emailRules"
                dense
                outlined
                clearable
              ></v-text-field>
              <v-text-field
                color="primary"
                v-model="password"
                :label="$t('login.password')"
                autocomplete="off"
                class="rounded-lg"
                :append-icon="showPassword ? '$eye' : '$eyeOff'"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                @click:append="toggleShowPassword"
                required
                dense
                outlined
                clearable
              ></v-text-field>

              <v-row
                no-gutters
                align="center"
                justify="center"
                justify-sm="space-between"
                class="mb-6"
              >
                <v-checkbox
                  color="grey darken-2"
                  class="mt-0 pt-0"
                  hide-details
                  v-model="rememberMe"
                >
                  <template v-slot:label>
                    <div class="text-caption">
                      {{ $t("login.rememberOnDevice") }}
                    </div>
                  </template>
                </v-checkbox>
                <!-- <div class=""> -->
                <a
                  @click="goToResetPassword"
                  class="text-decoration-underline text-caption font-weight-bold default--text mt-6 mt-sm-0"
                  :class="$vuetify.breakpoint.xs ? 'default--text' : ''"
                >
                  {{ $t("login.rememberPassword") }}
                </a>
                <!-- </div> -->
              </v-row>

              <!-- <div class="d-flex flex-column remember-password"> -->
              <v-btn
                :loading="loading"
                type="submit"
                color="primary"
                large
                block
                depressed
              >
                {{ $t("login.buttonLogin") }}
              </v-btn>
              <ResponseMessage
                :response="response"
                class="mt-3"
                @resend_activation="sendActivation"
              />
              <!-- <v-btn
                    v-if="isCordova"
                    :loading="loadingCardCode"
                    class="mt-0 mb-1"
                    color="secondary"
                    large
                    block
                    depressed
                    @click="scanCard()"
                  >
                    {{ $t("login.buttonScanCard") }}
                  </v-btn> -->
              <!-- </div> -->
            </v-card-text>
            <v-divider v-if="enableRegistration" class="mx-4" />
            <v-card-actions
              v-if="enableRegistration"
              class="d-flex flex-column default--text"
            >
              <div class="text-h2">
                {{ $t("login.messageRegister") }}
              </div>
              <v-btn
                @click="$emit('submit', false)"
                large
                outlined
                block
                depressed
                :to="{ name: 'RegistrationUser', path: '/registration-user' }"
              >
                {{ $t("login.buttonRegister") }}
              </v-btn>
            </v-card-actions>
            <strong v-if="showMessage" class="d-flex text-center px-12"
              >Se non possiedi ancora un account effettua la registrazione
              oppure scegli il tuo negozio</strong
            >
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
// import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";

import pushNotification from "~/mixins/pushNotification";

import { mapActions, mapGetters } from "vuex";

import SocialService from "~/service/socialService";

import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "~/validator/validationRules";
import securityService from "@/commons/service/securityService";

export default {
  name: "Login",
  props: {
    redirectParams: { type: Object, required: false }
  },
  mixins: [pushNotification],
  components: { ResponseMessage },
  data() {
    return {
      loading: null,
      loadingCardCode: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digita la tua email"), isEmail()],
      passwordRules: [
        requiredValue("Digita la tua password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null,
      enableRegistration: process.env.VUE_APP_ENABLE_REGISTRATION == "true",
      showMessage: false
    };
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    sendActivation() {
      securityService.resendRegistrationEmail(this.email);
    },
    doFBLogin() {
      var _this = this;
      window.facebookConnectPlugin.login(
        ["public_profile", "email"],
        function(userData) {
          SocialService.facebookLoginConnect(
            userData.authResponse.accessToken,
            true
          ).then(
            function(data) {
              if (data.user.login && data.user.login != "") {
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl = "/dashboard";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
              } else {
                //NUOVO UTENTE
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl =
                    "/registration-nocard?social_login=facebook";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
                // SecurityService.refreshLogin(
                //   data.socialRedirectUrl.indexOf("registration") >= 0
                // ).then(
                //   function() {
                //     this.closeLogin();
                //   },
                //   function() {
                //     this.closeLogin();
                //   }
                // );
              }
            },
            function(error) {
              console.log(error);
            }
          );
        },
        function(error) {
          console.log(error);
        }
      );
    },
    facebookLogin() {
      if (this.isCordova) {
        this.doFBLogin();
      } else {
        let redirectBaseUrl = `${window.location.protocol}//${
          window.location.hostname
        }${
          window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : ""
        }`;
        SocialService.openFacebookLoginPage(redirectBaseUrl);
      }
    },
    socialLoginGoogle() {
      if (this.isCordova) {
        let redirectBaseUrl =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : "");

        let url =
          "https://accounts.google.com/o/oauth2/auth?client_id=" +
          global.config.googleClientId +
          "&redirect_uri=" +
          redirectBaseUrl +
          "/ebsn/api/social-redir/google&prompt=consent&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

        SocialService.handleGoogleOnApp(url, "registration-nocard");
      } else {
        SocialService.openGoogleLoginPage();
      }
      this.$emit("submit", false);
    },
    // scanCard() {
    //   if (window.cordova && window.cordova.plugins.barcodeScanner) {
    //     try {
    //       this.loadingCardCode = true;
    //       // eslint-disable-next-line no-undef
    //       cordova.plugins.barcodeScanner.scan(
    //         result => {
    //           if (result) {
    //             this.loadingCardCode = false;
    //             // todo get card code and go to registration
    //           }
    //         },
    //         error => {
    //           this.loadingCardCode = false;
    //           console.error("Scanning failed", error);
    //         },
    //         {
    //           showFlipCameraButton: true,
    //           showTorchButton: true,
    //           resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    //           formats:
    //             this.formats ||
    //             "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
    //         }
    //       );
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // },
    async handleSubmit() {
      let _this = this;
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (_this.password.length > 0) {
        try {
          _this.loading = true;
          _this.response = {};
          await _this.doLogin({
            email: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe,
            token: token
          });
          if (_this.isAuthenticated) {
            global.EventBus.$emit("login");
            _this.$emit("loggedIn", true);
          }
          this.$emit("submit", true);
          if (_this.redirectParams) {
            _this.$router.push({
              name: _this.redirectParams.name,
              path: _this.redirectParams.path,
              params: _this.redirectParams.params
            });
          }
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    }
  },
  created() {
    let _this3 = this;
    global.EventBus.$on("showMessageLogin", () => {
      _this3.showMessage = true;
    });
  }
};
</script>
