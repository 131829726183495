<template>
  <v-row
    :ref="num"
    class="homepage number-counter d-flex justify-center align-center my-2"
  >
    <v-col
      v-for="(digit, index) in currentValue"
      :key="index"
      class="digit d-flex align-center justify-center mx-1 text-center font-weight-black rounded-sm"
    >
      {{ digit }}
    </v-col>
  </v-row>
</template>
<style scoped lang="scss">
.homepage.number-counter {
  color: var(--v-default-base);
  font-size: 1.2rem;
  .digit {
    width: 50px;
    height: 50px;
    border: 1px solid var(--v-white-base);
    background-color: var(--v-white-base);
  }
  .v-btn.v-btn--outlined {
    border-color: var(--v-white-base) !important;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .homepage.number-counter .digit {
    width: 30px;
    height: 30px;
  }
}
</style>
<script>
import customService from "@/service/customService";

export default {
  name: "NumberCounter",
  props: {
    num: { type: String, required: true },
    step: { type: Number, default: 1 },
    speed: { type: Number, default: 3000 },
    auto: { type: Boolean, default: true }
  },
  data() {
    return {
      currentValue: "",
      targetValue: ""
    };
  },

  methods: {
    animateValue() {
      const targetValue = parseInt(this.num.padStart(this.num.length, "0"));
      let current = parseInt(this.currentValue);
      const totalSteps = Math.ceil(this.speed / this.step);
      const diff = targetValue - current;
      const stepValue = diff / totalSteps;

      let steps = 0;
      const interval = setInterval(() => {
        if (steps >= totalSteps) {
          clearInterval(interval);
          return;
        }
        current += stepValue;
        // Ensure current value doesn't exceed target value
        if (
          (stepValue > 0 && current >= targetValue) ||
          (stepValue < 0 && current <= targetValue)
        ) {
          current = targetValue;
          clearInterval(interval);
        }
        this.currentValue = Math.round(current)
          .toString()
          .padStart(this.num.length, "0");
        steps++;
      }, this.step);
    },

    async treesCounter() {
      let numberOfTrees = await customService.treesCounter();
      //console.log("PPPPPPPP", numberOfTrees);
      return numberOfTrees;
    }
  },
  async mounted() {
    if (this.auto) {
      const numberOfTrees = await this.treesCounter();
      this.num = numberOfTrees.toString();
    }
    this.num = this.num.padStart(this.num.length, "0"); // assign padded value to num prop
    this.currentValue = "0".repeat(this.num.length); // initialize currentValue
    this.animateValue();
  }
};
</script>
